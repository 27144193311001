<template>
    <div class="modal-mask">
      <div class="modal-content-container">
        <div class="requested-products__main-container">
          <div class="requested-products__header-container not-print">
            <span class="requested-products__header-title">
              {{ modal.title }}
            </span>
            <span @click="closeDialog" class="material-icons requested-products__closebtn-icon">
              cancel
            </span>
          </div>
          <div class="requested-products__actions-container not-print">
            <div class="requested-products__filter-input-wrapper">
              <cc-select-v2 class="requested-products__filter-input" 
                            :tracker="'loj_id'" 
                            :text="'loj_descricao'" 
                            v-model="filters.store" 
                            :options="stores">
              </cc-select-v2>
            </div>
            <div class="requested-products__actions-wrapper"
                 :content="'Imprimir'"
                 :classes="'print-btn'"
                 @click="download_pdf()">
              <span class="material-icons requested-products__actions-icon">
                print
              </span>
              <span class="requested-products__actions-title">
                Imprimir
              </span>
            </div>
          </div>
          <div id="modalBodyPrint" class="requested-products__table-container">
            <cc-loader style="margin-top: 25vh;" v-show="loading" />
            <no-data-available v-if="products.length == 0 && !loading" :text="'Nenhum produto encontrado'" />
            <table v-show="!loading && products.length > 0" class="requested-products__table-wrapper">
              <thead class="requested-products__table-thead">
                <tr class="requested-products__table-thead-tr">
                  <th class="requested-products__table-th">Nome</th>
                  <th class="requested-products__table-th">Código</th>
                  <th class="requested-products__table-th">Embalagem</th>
                  <th class="requested-products__table-th">Qtd.</th>
                  <th class="requested-products__table-th">Estoque</th>
                </tr>
              </thead>
              <tbody class="requested-products__table-tbody">
                <tr class="requested-products__table-tbody-tr" 
                    v-for="(item, index) in products" 
                    :key="'prod-' + index"
                    :class="getSpecialBackground(index)">
                  <td class="requested-products__table-td">{{ item.pro_descricao }}</td>
                  <td class="requested-products__table-td">{{ item.pro_ean }}</td>
                  <td class="requested-products__table-td">{{ item.pro_generico == 1 ? item.pro_unidade_compra : item.pro_embalagem }}</td>
                  <td class="requested-products__table-td">{{ item.soma_quantidades || item.quantidade }}</td>
                  <td class="requested-products__table-td">{{ item.estoque || 0 }}</td>
                </tr>
              </tbody>
            </table>
            <a id="download-pdf" style="display:none;"></a>
          </div>
        </div>
      </div>
    </div>
      <!-- <cc-modal :modal="modal" @close="close">
          <div class="row container-fluid" slot="header">
              <div class="col-sm-7 pl-0">
                  <h5>
                      <i class="header-icon fas fa-box-open"></i>
                      {{ modal.title }}
                  </h5>
                  <p class="subtitle">Acompanhe os produtos de sua cotação</p>
              </div>
              <div class="col-sm-3 pl-0">
                <div class="row">
                  <div class="col">
                    <cc-select-v2 :tracker="'loj_id'" :text="'loj_descricao'" v-model="filters.store" :options="stores"></cc-select-v2>
                  </div>
                </div>
              </div>
              <div class="col-2 not-print">
                  <cc-button
                      :content="'Imprimir'"
                      :classes="'print-btn'"
                      :icon="'fa fa-print'"
                      @click="download_pdf()"
                    ></cc-button>
                    
              </div>
          </div>
          <div slot="body" class="row" id="modalBodyPrint">
              <div class="col-sm-10 only-print" style="text-align: left;color: gray;">
                  <h4 style="padding-right: 23px;text-align: left;color: gray;">Produtos da Cotação: {{quotation}}</h4>
              </div>
  
              <div class="col text-center">
                  <cc-loader v-show="loading"/>
              </div>
  
              <no-data-available v-if="products.length == 0 && !loading" :text="'Nenhum produto encontrado'" />
  
              <table class="table" v-if="products.length > 0 && !loading">
                  <thead>
                  <tr>
                      <th class="pl-4">Nome</th>
                      <th class="pl-2">Código</th>
                      <th>Embalagem</th>
                      <th>Qtd.</th>
                      <th class="text-center">Estoque</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in products" v-bind:key="'prod-' + index">
                      <td class="pl-3">{{ item.pro_descricao}}</td>
                      <td>{{ item.pro_ean}}</td>
                      <td>{{ item.pro_generico == 1 ? item.pro_unidade_compra : item.pro_embalagem}}</td>
                      <td>{{ item.soma_quantidades || item.quantidade }}</td>
                      <td class="text-center">{{ item.estoque || 0 }}</td>
                  </tr>
                  </tbody>
              </table>
              <a id="download-pdf" style="display:none;"></a>
          </div>
      </cc-modal> -->
  </template>
  
  <script>
  
  import QuotationService from "@/services/QuotationService";
  import RequestsService from "@/services/v2/requests.service";
  import ErrorHandlerService from "@/services/ErrorHandlerService";
  
  export default {
      props: ["quotation", "closeDialog"],
      data() {
          return {
              products: [],
              stores: [],
              loading: true,
              service: new RequestsService(),
              filters: {
                  store: null
              },
              modal: {
                  title: `Produtos da cotação #${this.quotation}`,
                  unpermitted_message: "Aceite o termo de consentimento para continuar.",
                  permitted: true,
                  style: {
                      width: "80%"
                  }
              }
          };
      },
      watch: {
          'filters.store' : function() {
              this.getProducts()
          }
      },
      methods: {
              close() {
                  this.$emit('close');
              },
          group_name() {
              return localStorage.getItem("grupo")
          },
          getProducts() {
              this.loading = true
              this.service
              .request_products(this.quotation, { store_id: this.filters.store ? this.filters.store.loj_id : null })
              .then(response => response.data)
              .then(data => {
                  this.loading = false
                  this.products = data;
              })
              .catch(error => {
                  this.loading = false
                  ErrorHandlerService.handle(error, this.$store, this.$store);
              });
          },
  
          getStores() {
  
              this.service
              .request_stores(this.quotation)
              .then(response => response.data)
              .then(data => {
                  this.stores = data;
                  this.stores.map(item =>{ item.loj_descricao = item.cot_id + " - " + item.loj_descricao});
              })
              .catch(error => {
                  this.loading = false
                  ErrorHandlerService.handle(error, this.$store, this.$store);
              });
          },
          download_pdf() {
              let callback = data => {
                  var file = new Blob([data], { type: "application/pdf" });
                  var fileURL = URL.createObjectURL(file);
                  var el = document.getElementById("download-pdf");
                  var title = "produtos-cotacao-" + this.quotation + ".pdf";
                  el.download = title
                  el.href = fileURL;
                  el.click();
                  setTimeout(function() {
                      window.URL.revokeObjectURL(fileURL);
                  }, 100);
              }
  
              this.is_loading_pdf = true;
              this.service.request_products_pdf_report(this.quotation, this.filters.store ? this.filters.store.loj_id : null).then(response => response.data)
              .then(callback).catch(error => {
                  this.is_loading_pdf = false;
                  ErrorHandlerService.handle(error, this.$store);
              });
          },
          print() {
              setTimeout(() => {
  
                  var conteudoModal = window.document.getElementById("modal").innerHTML;
  
                  var conteudoImpressao =
                      "<html>" +
                      "<head>" +
                      '<link rel="stylesheet" href="/css/app.css">' +
                      '<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic">' +
                      "<title>" +
                      "</title>" +
                      "<style> \
                          @media print {    \
                              .not-print, .not-print * { \
                              display: none !important; \
                              } \
                          } \
                      </style>" +
                      "</head>" +
                      "<body>" +
                      conteudoModal +
                      "</body>";
  
                  let name = '_blank';
                  let specs = ['fullscreen=yes','titlebar=yes', 'scrollbars=yes'];
                  let replace = true;
  
                  specs = !!specs.length ? specs.join(',') : '';
  
                  var telaImpressao = window.open("", '_blank', ['fullscreen=yes','titlebar=yes', 'scrollbars=yes'], true);
  
                  telaImpressao.document.write(conteudoImpressao);
  
                  setTimeout(() => {
                      telaImpressao.document.close();
                      telaImpressao.focus();
                      telaImpressao.print();
                      telaImpressao.close();
                  }, 1500);
  
                  this.imprimindo = false;
              }, 500);
          },
          getSpecialBackground (index) {
            if (index % 2 != 0) return 'page-table-line-special'
          },
      },
      async mounted() {
          this.getStores();
          this.getProducts();
      }
  };
  </script>
  
  <style lang="scss" scoped>
  .modal-mask {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
  }
  .modal-content-container{
    background-color: white;
    min-width: 50vw;
    border-radius: 12px;
  }
  .requested-products__main-container {
    width: 90vw;
    height: 100vh;
  }
  .requested-products__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 10px 10px 0px 0px;
    background-color: #ff7110;
  }
  .requested-products__header-title {
    color: #FFFFFF;
    letter-spacing: -2%;
    font-weight: 500;
    font-size: 32px;
  }
  .requested-products__closebtn-icon {
    color: #FFFFFF;
    font-size: 30px;
    cursor: pointer;
  }
  .requested-products__actions-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px;
  }
  .requested-products__filter-input-wrapper {
    width: 30vw;
  }
  .requested-products__filter-input {
  }
  .requested-products__actions-wrapper {
    display: flex;
    align-items: center;
    padding: 12px;
    margin: 0px 10px;
    border-radius: 8px;
    border: 1px solid #E5E5E5;
    background-color: #FAFAFC;
    cursor: pointer;
  }
  .requested-products__actions-icon {
    color: #FF7110;
    margin-right: 1rem;
  }
  .requested-products__actions-title {
    font-weight: 400;
    font-size: 16px;
    color: #505050;
  }
  .requested-products__table-container {
    margin: 20px;
    overflow-y: auto;
    max-height: 70vh;
  }
  .requested-products__table-wrapper {}
  .requested-products__table-thead {}
  .requested-products__table-thead-tr {
    background-color: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    color: #605F5F;
    font-size: 14px;
    font-weight: 400;
  }
  .requested-products__table-th {
    font-size: 14px;
    font-weight: 600;
  }
  .requested-products__table-tbody {}
  .requested-products__table-tbody-tr {}
  .requested-products__table-td {
    color: #605F5F;
    font-size: 14px;
    font-weight: 300;
  }
  .page-table-line-special{
    background: #F7F7F7;
  }
  @media only screen and (max-width: 750px) {
    .requested-products__table-container {
      max-height: 62vh;
    }
    .requested-products__actions-container {
      flex-direction: column;
      margin: 10px;
    }
    .requested-products__filter-input-wrapper {
      margin-bottom: 20px;
    }
    .requested-products__header-title {
      font-size: 24px;
    }
  }
  
      @import "./requested-products.modal";
  </style>
