<!-- <style lang="scss" scoped>
	@import "./manage-request-sellers.modal";
</style>
<template>
	<cc-modal :modal="modal" @close="close">
		<div slot="body" class="container-fluid pt-0 sellers">
			<div class="row contents shadow p-4">
				<div class="col-6">
					<label for="" class="label-control">Fornecedores da compra/cotação</label>
					<cc-loader v-show="is_loading_request_sellers"/>
					<div class="col-12 mb-3">
						<div class="row">
							<div class="col p-0">
								<cc-search
                                    :placeholder="'Pesquisar vendedor ou fornecedor'"
                                    class="mt-1" v-model="request_sellers_filters.name"
                                    :noicon="true"
                                    @keyup="filter_request_sellers(request_sellers, request_sellers_filters.name)" />
							</div>
							<div class="col-4 filter-controls">
								<cc-button @click="remove_all" :content="'Remover Todos'" :icon="'fa fa-trash'" :classes="'fixed danger-outline'" />
							</div>
						</div>
					</div>
					<div class="sellers-list list-group">
						<table class="table">
							<thead>
								<tr>
									<th class="text-left px-3">Fornecedor</th>
									<th class="text-left px-0">Vendedor</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-show="seller.visible" v-for="seller in request_sellers" :key="seller.id">
									<td class="text-left px-3"><b>{{ seller.provider ? seller.provider.name : '' }}</b></td>
									<td class="text-left px-0">{{ seller.name }}</td>
									<td>
										<i v-show="!seller.loading" class="fa fa-trash remove-btn" @click.stop="remove(seller)" />
										<cc-loader v-show="seller.loading" :show_txt="false" class="loader float-right" />
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="col-6">
					<label for="" class="label-control">Fornecedores disponíveis</label>
					<cc-loader v-show="is_loading_available_sellers"/>
					<div class="col-12 mb-3">
						<form @submit.prevent="">
							<div class="row">
								<div class="col p-0">
									<cc-search
                                        :noicon="true"
                                        :placeholder="'Pesquisar vendedor ou fornecedor'"
                                        class="mt-1"
                                        :disabled="filters.by_owner"
                                        v-model="filters.name"
                                        @keyup="filter_sellers(sellers, filters.name)" />
								</div>
                                <div class="col pb-2">
									<cc-checkbox
                                        class="mt-2"
                                        :label="'Vendedores do cliente'"
                                        @change="filter_sellers"
                                        v-model="filters.by_owner" />
								</div>
							</div>
						</form>
					</div>
					<div class="sellers-list list-group">
						<table class="table">
							<thead>
								<tr>
									<th></th>
                                    <th class="text-left px-0">Telefone</th>
									<th class="text-left px-0">Vendedor</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="seller in sellers" :key="seller.id" v-show="seller.visible">
									<td>
										<cc-loader v-show="seller.loading" :show_txt="false" class="loader float-left" />
										<i v-show="!seller.loading" class="fa fa-plus add-btn" @click.stop="add(seller)"/>
									</td>
                                    <td class="text-left px-0"><cc-whatsapp :phone="seller.phone" /></td>
									<td class="text-left px-0">
                                        (<b>#{{ seller.provider ? seller.provider.name : '' }}</b>) {{ seller.name }}
                                    </td>
								</tr>
                                <tr>
                                    <td colspan="3">
                                        <Pagination
                                            classes="orange justify-end"
                                            :total="total_items"
                                            :items_by_page_value="items_by_page_value"
                                            :page="page"
                                            :page_limit="9"
                                            v-on:change="($event) => {
                                                load_sellers($event)
                                                return $event
                                            }"
                                        ></Pagination>
                                    </td>
                                </tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</cc-modal>
</template> -->

<template>
	<div class="modal-mask">
	  <div class="modal-content-container">
		<div class="manage-sellers__main-container">
		  <div class="manage-sellers__header-container">
			<span class="manage-sellers__header-title">
			  Gerenciar fornecedores #{{ request.id }}
			</span>
			<span @click="close" class="material-icons manage-sellers__closebtn-icon">
			  cancel
			</span>
		  </div>
		  <div class="manage-sellers__providers-container">
			<div class="manage-sellers__left-container manage-sellers__left-wrapper">
				<div class="manage-sellers__title-wrapper">
				  <span class="manage-sellers__title">Fornecedores da compra/cotação </span>
				</div>
				<div class="manage-sellers__input-wrapper">
				  <input :placeholder="'Procurar vendedor ou fornecedor'" 
						 class="manage-sellers__input"
						 v-model="request_sellers_filters.name" 
						 @keyup="filter_sellers(request_sellers, request_sellers_filters.name)">
				  <span @click="filter_sellers(request_sellers, request_sellers_filters.name)" class="material-icons-outlined manage-sellers__input-icon">search</span>
				</div>
				<div class="manage-sellers__actions-wrapper">
				  <span class="material-icons-outlined manage-sellers__actions-icon">delete</span>
				  <span @click="remove_all" 
						:classes="'fixed danger-outline'" 
						class="manage-sellers__actions-title">
					Remover todos
				  </span>
				</div>
				<div class="manage-sellers__table-container-left">
				  <table class="manage-sellers__table-wrapper">
					<thead class="manage-sellers__table-thead">
					  <tr class="manage-sellers__table-thead-tr">
						<th class="manage-sellers__table-th">Forcenedor</th>
						<th class="manage-sellers__table-th">Vendedor</th>
						<th class="manage-sellers__table-th">Ação</th>
					 </tr>
					</thead>
					<tbody class="manage-sellers__table-tbody">
					  <tr v-show="seller.visible"
						  v-for="seller in request_sellers"
						  :key="seller.usu_id"
						  class="manage-sellers__table-tbody-tr">
						<td class="manage-sellers__table-td">{{ seller.provider ? seller.provider.name : '' }}</td>
						<td class="manage-sellers__table-td">{{ seller.name }}</td>
						<td class="manage-sellers__table-td">
						  <span v-show="!seller.loading" @click.stop="remove(seller)" class="material-icons-outlined delete-icon">delete</span>
						  <cc-loader style="width: 4vw;" v-show="seller.loading" :show_txt="false" class="loader" />
					  </td>
					  </tr>
					</tbody>
				  </table>
				</div>
			</div>
			<div class="manage-sellers__right-container manage-sellers__right-wrapper">
				<div class="manage-sellers__title-wrapper">
					<span class="manage-sellers__title">Fornecedores disponíveis</span>
				</div>
				<div class="manage-sellers__right-actions__wrapper">
					<div class="manage-sellers__right-input__wrapper">
					<input @keyup="filter_sellers(sellers, filters.name)" v-model="filters.name" :placeholder="'Procurar vendedor ou fornecedor'" class="manage-sellers__right-input">
					<span class="material-icons-outlined manage-sellers__input-icon">search</span>
					</div>
					<!-- <div class="manage-sellers__right-filter__wrapper">
					<cc-select-v2 :placeholder="'Filtrar por grupo'" 
									class="manage-sellers__right-filter"
									:tracker="'id'" 
									:text="'nome'" 
									:options="groups" 
									v-model="filters.group">
					</cc-select-v2>
					</div> -->
				</div>
				<div class="manage-sellers__table-container">
					<table class="manage-sellers__table-wrapper">
					<thead class="manage-sellers__table-thead">
						<tr class="manage-sellers__table-thead-tr">
						<th class="manage-sellers__table-th">Forcenedor</th>
						<th class="manage-sellers__table-th">Vendedor</th>
						<th class="manage-sellers__table-th">Ação</th>
						</tr>
					</thead>
					<tbody class="manage-sellers__table-tbody">
						<tr v-for="seller in sellers" 
							:key="seller.usu_id" 
							v-show="seller.visible" 
							class="manage-sellers__table-tbody-tr">
						<td class="manage-sellers__table-td">{{ seller.provider ? seller.provider.name : '--' }}</td>
						<td class="manage-sellers__table-td">{{ seller.name }}</td>
						<td class="manage-sellers__table-td">
							<span class="material-icons-outlined add-icon"
								v-show="!seller.loading"
								@click.stop="add(seller)">
							add_circle_outline
							</span>
							<cc-loader style="width: 4vw;" v-show="seller.loading" :show_txt="false" class="loader" />
						</td>
						</tr>
					</tbody>
					</table>
				</div>
			</div>
		  </div>
		</div>
	  </div>
	</div>
  </template>


<script>

import SellerServiceV3 from "@/services/v3/providers/seller.service";
import SellersService from "@/services/v2/sellers.service";
import RequestsService from "@/services/v3/request.service";
import RequestsServiceV2 from "@/services/v2/requests.service";
import ErrorHandlerService from '@/services/ErrorHandlerService';
import Pagination from "@/components/cliente/base-components/Pagination";
import SellerGroupService from "@/services/v2/seller-group.service";
import { loaderMixin } from '@/mixins/sweet-loader.mixin';

export default {
	mixins: [ loaderMixin ],
	props: {
		request: {
			type: Object,
			required: true
		}
	},
	components: {
		Pagination
    },
  	data() {
		return {
			sellers: [],
			request_sellers: [],
			filters: {
				name: '',
                by_owner: false
			},
			request_sellers_filters: {
				name: ''
			},
			loading: false,
            loading_general: false,
            loading_seller: true,
            loading_request_sellers: true,
            loading_group: true,
			total_items : 0,
			groups: [],
            page: 1,
            items_by_page_value: 25,
			is_loading_available_sellers: false,
			is_loading_request_sellers: false,
			service: new SellersService(),
            svc_v3: new SellerServiceV3(),
			group_svc: new SellerGroupService(),
			req_svc: new RequestsService(),
            req_svcv2: new RequestsServiceV2(),
			modal: {
				title: `gerenciar fornecedores #${this.request.id}`,
				subtitle: `Gerencie os fornecedores que participam da sua compra/cotação`,
				icon_title: 'fas fa-users',
				cancel_text: 'Voltar',
				style: {
					width: "90%"
				}
			},
		};
	},
  	methods: {
		toggle_activation(seller, collection) {
			collection.filter(s => s.id !== seller.id).forEach(s => s.active = false)
			seller.active = !seller.active
			this.$forceUpdate()
		},
		add(seller) {
			this.$set(seller, 'loading', true)
			return this.req_svcv2.add_seller(this.request.id, { cot_id: this.request.id, vendedor_id: seller.id })
            .then(() => this.svc_v3.activate_client(seller.id, this.request.client.id))
			.then(() => this.add_to_list(seller.id))
			.then(() => this.$set(seller, 'loading', false))
		},
		add_to_list(seller_id) {
			this.sellers.filter(ss => ss.id == seller_id).forEach(s => this.request_sellers.push(s))
			this.sellers = this.sellers.filter(s => s.id !== seller_id)
		},
		remove_from_list(seller_id) {
			this.request_sellers.filter(ss => ss.id == seller_id).forEach(s => this.sellers.push(s))
			this.request_sellers = this.request_sellers.filter(s => s.id !== seller_id)
		},
		remove(seller) {
			this.$set(seller, 'loading', true)
			return this.req_svcv2.remove_seller(this.request.id, seller.id ).then(() => {
				this.remove_from_list(seller.id)
			}).then(() => this.$set(seller, 'loading', false))
		},
		close() {
			this.$emit("close");
		},
		load_sellers(page = 1) {
            this.page = page
			this.loading_seller = true;
			return this.svc_v3.load({
                page: this.page,
                page_size: this.items_by_page_value,
                name: this.filters.name,
                client_id: this.filters.by_owner ? this.request.client.id : null
            }).then(response => response.data)
			.then(data => {
				this.sellers = data.data;
				this.sellers.forEach(s => s.visible = true)
				this.total_items = data.meta.total;
                this.loading_seller = false;
                this.loading_general = !this.loading_general;
			}).catch(error => {
				ErrorHandlerService.handle(error, this.$store);
			});
		},
        load_sellers_out() {
			this.loading_seller = true;
			return this.req_svc.sellers_out(this.request.id)
			.then(response => response.data)
			.then(data => {
				this.sellers = data;
				this.sellers.forEach(s => s.visible = true)
				this.total_items = 1;
                this.loading_seller = false;
                this.loading_general = !this.loading_general;
			})
			.catch(error => {
				ErrorHandlerService.handle(error, this.$store);
			});
		},
		load_request_sellers() {
			this.loading_request_sellers = true;
			return this.req_svc.sellers(this.request.id)
			.then(response => response.data)
			.then(data => {
				this.request_sellers = data.map(rs => rs.seller);
				this.request_sellers.forEach(s => s.visible = true)
                this.loading_request_sellers = false;
                this.loading_general = !this.loading_general;
			})
			.catch(error => {
				ErrorHandlerService.handle(error, this.$store);
			});
		},
		filter_sellers() {
            if(this.filters.by_owner) {
                this.load_sellers_out()
            } else {
                this.load_sellers()
            }
		},
        filter_request_sellers(collection, filter = '') {
			collection.forEach(s => s.visible = false)
			collection
			.filter(s => s.name.toLowerCase().includes(filter.toLowerCase()) || s.provider.name.toLowerCase().includes(filter.toLowerCase()))
			.forEach(s => s.visible = true)
			this.$forceUpdate()
		},
		remove_all() {
			this.confirm_action({
				message: "Confirma remoção de todos os vendedores dessa compra/cotação?",
				subtitle: "Os fornecedores serão removidos da compra/contação, você poderá adiciona-los posteriormente!",
				callback: () => {
					this.present_loader("Removendo vendedores do compra/cotação...")
					return this.req_svcv2.remove_all_sellers(this.request.id)
					.then(() => this.request_sellers.forEach(s => this.remove_from_list(s.id)))
					.then(() => this.dismiss_loader())
				}
			})
		}
	},
	watch: {
		'filters.group' : function(val) {
			if(val) {
				this.load_group(val);
			} else {
				this.load_sellers()
			}
		},
        'loading_general' : function() {
            if(this.loading_seller && this.loading_request_sellers && this.loading_group){
                this.present_loader("Carregando dados...")
            }

            if(!this.loading_seller && !this.loading_request_sellers && !this.loading_group){
                this.dismiss_loader()
            }
		}
	},
	mounted() {
        this.loading_general = !this.loading_general;
		this.load_sellers()
        .then(this.load_request_sellers)
        .then(() => this.dismiss_loader())
	}
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}
.modal-content-container {
  background-color: white;
  min-width: 50vw;
  border-radius: 12px;
}
.manage-sellers__main-container {
  width: 100vw;
  height: 105vh;
}
.manage-sellers__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: #ff7110;
}
.manage-sellers__header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 32px;
}
.manage-sellers__closebtn-icon {
  color: #ffffff;
  font-size: 30px;
  cursor: pointer;
}
.manage-sellers__providers-container {
  display: flex;
  justify-content: space-evenly;
  margin: 20px 0px;
}
.manage-sellers__left-container {
  width: 45vw;
  height: 85vh;
  border-radius: 8px;
  -webkit-box-shadow: 4px 4px 10px 1px rgba(0,0,0,0.5); 
  box-shadow: 4px 4px 10px 1px rgba(0,0,0,0.2);
}
.manage-sellers__left-wrapper {
  padding: 10px;
}
.manage-sellers__title-wrapper {
  margin-bottom: 1.6vh;
}
.manage-sellers__title {
  font-size: 24px;
  font-weight: 400;
  color: #707070;
}
.manage-sellers__input-wrapper {
  margin: 20px 0px;
  display: flex;
  align-items: center;
}
.manage-sellers__input {
  width: 45vw;
  height: 5.5vh;
  padding: 0px 10px;
  border: 1px solid #c2c2c3;
  border-radius: 5.23px;
  font-weight: 400;
  font-size: 14px;
  color: #A1A1A1;
}
.manage-sellers__input-icon {
  position: relative;
  font-size: 20px;
  left: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #A1A1A1;
}
.manage-sellers__actions-wrapper {
  display: flex;
  width: 16vw;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #E5E5E5;
  background-color: #FAFAFC;
  cursor: pointer;
  margin-bottom: 2vh;
  justify-content: center;
}
.manage-sellers__actions-icon {
  color: #FF7110;
  margin-right: 1rem;
}
.manage-sellers__actions-title {
  font-weight: 400;
  font-size: 16px;
  color: #505050;
}
.manage-sellers__table-container {
  overflow-y: auto;
  height: 80%;
}
.manage-sellers__table-container-left {
  overflow-y: auto;
  height: 65%;
}
.manage-sellers__table-wrapper {}
.manage-sellers__table-thead {}
.manage-sellers__table-thead-tr {
  background-color: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  color: #605F5F;
  font-size: 14px;
  font-weight: 400;
}
.manage-sellers__table-th {
  font-size: 14px;
  font-weight: 600;
}
.manage-sellers__table-tbody {}
.manage-sellers__table-tbody-tr {}
.manage-sellers__table-td {
  color: #605F5F;
  font-size: 14px;
  font-weight: 300;
}
.delete-icon {
  color: #FF7110;
  cursor: pointer;
}
.add-icon {
  color: #FF7110;
  cursor: pointer;
}
.manage-sellers__right-container {
  width: 45vw;
  height: 85vh;
  border-radius: 8px;
  -webkit-box-shadow: 4px 4px 10px 1px rgba(0,0,0,0.5); 
  box-shadow: 4px 4px 10px 1px rgba(0,0,0,0.2);
}
.manage-sellers__right-wrapper {
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.manage-sellers__right-actions__wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 2vh;
}
.manage-sellers__right-input__wrapper {
  margin: 8px 0px;
  display: flex;
  align-items: center;
  width: 28vw;
}
.manage-sellers__right-input {
  width: 40vw;
  height: 5.5vh;
  padding: 0px 10px;
  border: 1px solid #c2c2c3;
  border-radius: 5.23px;
  font-weight: 400;
  font-size: 14px;
  color: #A1A1A1;
}
.manage-sellers__right-filter__wrapper {}
.manage-sellers__right-filter {
  width: 15vw !important;
  border: none !important;
}
.page-table-line-special{
  background: #F7F7F7;
}

@media only screen and (max-width: 750px) {
  .manage-sellers__providers-container {
	flex-direction: column;
  }
  .manage-sellers__header-title {
    font-size: 24px;
  }
  .manage-sellers__left-container {
	width: 90vw;
	height: 40vh;
	overflow-x: auto;
	overflow-y: auto;
	margin-left: 20px;
	margin-bottom: 20px;
  }
  .manage-sellers__right-container {
	width: 90vw;
	height: 40vh;
	overflow-x: auto;
	overflow-y: auto;
	margin-left: 20px;
  }
  .manage-sellers__right-actions__wrapper {
    flex-direction: column;
  }
  .manage-sellers__actions-wrapper {
    width: 52vw;
  }
  .manage-sellers__right-input__wrapper {
	width: 80vw;
	margin-left: 20px;
  }
  .manage-sellers__right-input {
    width: 75vw;
  }
  .manage-sellers__right-filter__wrapper {
	
  }
  .manage-sellers__right-filter {
	  width: 75vw !important;
  }
  .manage-sellers__input-icon {
	display: none;
  }
  .manage-sellers__title {
	font-size: 20px;
  }
  .manage-sellers__input {
	width: 90vw;
  }
}
@import "./manage-request-sellers.modal";
</style>
